import AxiosClient from "./Http.client";
import Swal from 'sweetalert2'
export const AddData = async (url, data, flag = false) => {
    var headers = {
        "Content-type": "application/json",
    }
    if (flag) {
        headers = {
            'Content-Type': 'multipart/form-data',
        }
    }
    // return AxiosClient.post(url, data, headers).then((res) => {
    //     return res.data;
    // }).catch((err) => {
    //     return err;
    // });
    try {
        const response = await AxiosClient.post(url, data, headers);
        Swal.fire({
            title: 'Success!',
            text: 'Successfully Done',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
        return response.data;
    } catch (error) {
     
        Swal.fire({
            title: 'Error!',
            text: 'Error storing data:'+error.response.data,
            icon: 'error',
            confirmButtonText: 'Ok'
        })
        return false;
    }
};

export const checkAuth = async (url) => {
    return AxiosClient.get(url).then((res) => {

        return res.data;
    }).catch((err) => {
        return err;
    });
};

export const Authentication = async (url, data) => {
    var headers = {
        "Content-type": "application/json",
    }
    return AxiosClient.post(url, data, headers).then((res) => {
        return res.data;
    }).catch((err) => {
        return err.response.data;
    });
};


export const ViewData = async (url) => {
    try {
        const response = await AxiosClient.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
};

export const removeData = async (url) => {
    try {
        const response = await AxiosClient.delete(url);
        Swal.fire({
            title: 'Success!',
            text: 'Successfully Done',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
        return response.data;
    } catch (error) {
        Swal.fire({
            title: 'Error!',
            text: 'Error deletind data:', error,
            icon: 'error',
            confirmButtonText: 'Ok'
        })
        return [];
    }
};